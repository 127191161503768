/*@import url("https://fonts.googleapis.com/css2?family=Alexandria&family=Cairo&display=swap");*/
.App {
  /* background-color: #0d0d0d; */
  color: #424242;
  padding: 0px;
  font-family: "Cairo", sans-serif !important;
}

.body_back {
  background-color: #f2f2f2;
  /* margin-top: -142px; */
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 20px;
}

.admin_back {
  background-color: #f2f2f2;
}

/***** Navbar style ***/
.navbar_button {
  border-radius: 0px !important;
  font-size: 16px !important;
  /* margin-bottom: -1px !important; */
  /* padding-bottom: 14px !important; */
}
.navbar_button:hover,
.active_page {
  background-color: rgba(255, 102, 0, 0) !important;
  /* border-bottom: 4px solid #fff !important;
  padding-bottom: 12px !important; */
  color: #000000 !important;
}

.right {
  text-align: right !important;
}

.account_menu {
  left: unset !important;
  right: -85px !important;
  width: 300px !important;
  top: 40px !important;
}
@media only screen and (max-width: 900px) {
  .navbar_button:hover,
  .active_page {
    border-bottom: 2px solid #ff5e18 !important;
    padding-bottom: 4px !important;
  }
}

/***** Buttons style ***/
button {
  text-transform: capitalize !important;
  font-family: "Cairo", sans-serif !important;
}

.outlineOrange_button {
  border: 1px solid #2c5378 !important;
  color: #2c5378 !important;
  font-weight: 500 !important;
}

.outlinePrimary_button {
  border: 1px solid white !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.outlinePrimary_button:hover {
  background-color: white !important;
  color: #2c5378 !important;
}

.primary_button {
  color: #ffffff !important;
  font-weight: 500 !important;
  background-color: #2c5378 !important;
}

.orangePrimary_button {
  border: 1px solid #2c5378 !important;
  font-weight: 500 !important;
  color: #fff !important;
  border-radius: 15px !important;
}

.orangePrimary_button:hover {
  background-color: #ff5e18 !important;
  color: #fff !important;
}
.orange_icon_button {
  color: #fff !important;
  font-size: 26px !important;
  background-color: #ff5e18 !important;
  border-radius: 15px !important;
  height: 25px !important;
  padding-top: 2px !important;
  padding-bottom: 8px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.hide_button {
  background-color: #00000000 !important;
  border: 0px solid rgba(255, 255, 255, 0) !important;
  color: #e0e0e000 !important;
}
/***** Form style ***/

.MuiSlider-thumb {
  color: #ff5e18;
}
.MuiSlider-rail {
  color: gray;
}
.MuiSlider-track {
  color: #ff5e18;
}

.review_progress .css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #f2c94c !important;
}
.en_radius .MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.en_radius .MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ar_radius .MuiTableRow-root td:first-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ar_radius .MuiTableRow-root td:last-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.MuiTableRow-root tr {
  margin-top: 10px !important;
}
.dark_table {
  border-collapse: separate !important;
  border-spacing: 0px 10px !important;
}
.dark_table_scroll {
  height: 400px !important;
  overflow-y: scroll !important;
}
.dark_table_scroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
.dark_table_scroll::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f14b51;
}
.divider::after,
.divider::before {
  background-color: #ffffff;
  height: 14px !important;
}
.divider {
  margin: 0px !important;
}

.verify_character {
  border: none;
  font-size: 20px;
  border-radius: 12px;

  /* light theme */
  box-shadow: 0 2px 0 #e4e2f5;
  border: 1px solid #e0e0e0;
}
.character--selected {
  border: 2px solid #2c5378 !important;
  border-color: #2c5378 !important;
}

.camera {
  position: absolute;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  padding-top: 20px;
  z-index: 2;
  top: 0;
  display: none;
  text-align: center !important;
}
.camera_div {
  position: relative;
  z-index: 1;
  padding: 0px !important;
  display: inline-block;
}
.camera_div:hover .camera {
  display: block;
}

.camera_div:hover {
  opacity: 0.5;
  cursor: pointer;
}
.camera_div img {
  border-radius: 50%;
}

.camera2 {
  position: absolute;
  width: 196px;
  height: 196px;
  border-radius: 10px;
  padding-top: 70px;
  z-index: 2;
  top: 0;
  display: none;
  text-align: center !important;
}
.camera_div2 {
  position: relative;
  z-index: 1;
  padding: 0px !important;
  display: inline-block;
}
.camera_div2:hover .camera2 {
  display: block;
}

.camera_div2:hover {
  opacity: 0.5;
  cursor: pointer;
}
.camera_div2 img {
  border-radius: 10px;
}

.hover_img {
  width: 96px;
  height: 96px;
}

.hover_img2 {
  width: 200px;
  height: 200px;
}

.loadingContainer {
  display: "flex";
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 45%;
  padding-right: 45%;
}

.hover_headCell:hover span,
.hover_headCell span,
.hover_headCell span svg {
  color: #2c5378 !important;
}
#scroll_tier {
  height: 300px;
  overflow-y: scroll;
}
#scroll_tier::-webkit-scrollbar {
  width: 10px !important;
  border-radius: 10px !important;
  background-color: #f5f5f5 !important;
}
#scroll_tier::-webkit-scrollbar-thumb {
  border-radius: 7px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-color: #2c5378 !important;
}
/***** Side Bar style ***/

.active_item {
  background: #2c5378 !important;
  color: #ffffff !important;
  border-radius: 12px !important;
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.sideBar_item {
  border-radius: 12px !important;
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.73) !important;
}
.active_subItem {
  background: #edf4f4 !important;
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #2c5378 !important;
  border-radius: 8px !important;
}
.sideBar_subItem {
  font-family: "Alexandria" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #333333 !important;
  border-radius: 8px !important;
}
.file_uploader label {
  min-width: 100% !important;
  max-width: 100% !important;
  margin-top: 20px;
  border: 2px dashed #2c5378 !important;
}
.file_uploader svg path {
  fill: #2c5378 !important;
}
.file_uploader span {
  /* color: white !important; */
}
.dashboard_card {
  background-color: #2c5378;
  /* color: white; */
  border-radius: 30px;
}

.client_dialog .MuiPaper-root {
  background-color: #2b2727 !important;
  color: white !important;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.file_btn {
  padding: 10px !important;
}

#scroll_numbers {
  height: 300px;
  overflow-y: scroll;
}
#scroll_numbers::-webkit-scrollbar {
  width: 10px !important;
  border-radius: 10px !important;
  background-color: #f5f5f5 !important;
}
#scroll_numbers::-webkit-scrollbar-thumb {
  border-radius: 7px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-color: #2c5378 !important;
}

.css-dcn0l9-MuiListItemIcon-root,.css-cveggr-MuiListItemIcon-root,.css-17ok0gr-MuiListItemIcon-root{
  min-width: 50px !important;
}
.css-1p823my-MuiListItem-root{

  /*padding-left: 10px !important;*/
  /*padding-right: 10px !important;*/
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
